/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 106번째 로그에서는 AWS 맥미니 지원, AWS TAM, 노션 & 슬랙 장애에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "맥미니 M1"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/mac-mini/"
  }, "M1 Mac mini - Apple")), "\n"), "\n", React.createElement(_components.h3, null, "AWS 맥미니 인스턴스 지원"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/new-use-mac-instances-to-build-test-macos-ios-ipados-tvos-and-watchos-apps/"
  }, "Amazon EC2 Mac 인스턴스를 사용한 macOS, iOS, iPadOS, tvOS 및 watchOS 앱 빌드 및 테스트 - Amazon Web Services 한국 블로그")), "\n"), "\n", React.createElement(_components.h3, null, "AWS TAM"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/careers/support/"
  }, "Korea Support - AWS Korea Recruitment")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
